import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Image from "common/components/Image";
import Container from "common/components/UI/Container";
import List from "common/components/List";

import PartnerSectionWrapper, { ListGroup } from "./partner.style";
import Event from "common/assets/image/Event_deets.png";

const PartnerSection = ({
  row,
  col,
  title,
  description,
  descriptionBold,
  button,
  textArea,
  imageArea,
  btnStyle,
  btnStyleWrapper,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      Tick: file(relativePath: { eq: "image/agency/tick-circle.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agencyJson {
        work_hard_list {
          id
          title
        }
      }
    }
  `);

  const ButtonGroup = () => (
    <Fragment>
      <Button
        title="Save my spot"
        {...btnStyle}
        onClick={() =>
          (window.location.href =
            "https://www.eventbrite.com/e/the-journey-to-empowerment-tickets-159085580253")
        }
      />
    </Fragment>
  );

  return (
    <PartnerSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            <Image src={Event} alt="Domain Image" />
          </Box>
          <Box {...col} {...textArea}>
            <Heading
              {...title}
              content="The empowerment experience, designed for transformation"
            />
            <Text
              {...description}
              content="Saturday July 17th 9:30 - 3:30 PDT / 12:30 - 6:30 EDT. Virtual Live event. Only $247 ($399 value)"
            />
            {/* <Text
              {...descriptionBold}
              content="Early bird special - Only $45 ($350 value) before May 1st"
            /> */}
            <ListGroup>
              {Data.agencyJson.work_hard_list.map((item) => (
                <List
                  className="list-item"
                  key={item.id}
                  text={item.title}
                  icon={
                    <Image
                      fluid={
                        (Data.Tick !== null) | undefined
                          ? Data.Tick.childImageSharp.fluid
                          : {}
                      }
                      src={Data.Tick.childImageSharp.fluid.src}
                      objectFit="contain"
                      alt="Tick Icon"
                    />
                  }
                />
              ))}
            </ListGroup>
            <Box {...btnStyleWrapper}>
              <ButtonGroup />
            </Box>
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  btnStyle: PropTypes.object,
  btnStyleWrapper: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center",
  },
  imageAreaRow: {
    flexDirection: "row-reverse",
  },
  col: {
    pr: "15px",
    pl: "15px",
  },
  textArea: {
    width: ["100%", "100%", "55%", "50%", "42%"],
  },
  imageArea: {
    width: ["100%", "100%", "45%", "50%", "58%"],
    mb: ["40px", "40px", "0", "0", "0"],
  },
  title: {
    fontSize: ["26px", "26px", "32px", "40px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "15px",
    lineHeight: "1.25",
  },
  description: {
    fontSize: "16px",
    color: "#343d48cc",
    lineHeight: "1.75",
  },
  descriptionBold: {
    fontSize: "16px",
    color: "black",
    lineHeight: "1.75",
    mb: "33px",
    fontWeight: "600",
  },
  button: {
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "secondaryWithBg",
    minWidth: "150px",
  },
  btnStyleWrapper: {
    textAlign: "center",
  },
  btnStyle: {
    minWidth: ["120px", "156px"],
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "16px",
  },
};

export default PartnerSection;
