import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import { openModal, closeModal } from "@redq/reuse-modal";
import Box from "common/components/Box";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import VideoSectionWrapper from "./videoSection.style";

const IntroVideo = () => (
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/RSRiEEwsClw"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  />
);

const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const VideoSection = ({
  btnStyle,
  btnStyleWrapper,
  sectionHeader,
  sectionTitle,
  buttonStyle,
  sectionSubTitle,
  imageWrapper,
}) => {
  // Video modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        default: {
          width: "100%",
          height: "100%",
          x: 0,
          y: 0,
        },
      },
      component: IntroVideo,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  const Data = useStaticQuery(graphql`
    query {
      featureImage: file(relativePath: { eq: "image/Video.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const ButtonGroup = () => (
    <Fragment>
      <Button
        title="I am interested"
        {...btnStyle}
        onClick={() =>
          (window.location.href =
            "https://www.eventbrite.com/e/the-journey-to-empowerment-tickets-159085580253")
        }
      />
    </Fragment>
  );

  return (
    <VideoSectionWrapper id="videoSection">
      <Container>
        <Box {...sectionHeader}>
          {/* <Text content="HOW WE WORK" {...sectionSubTitle} /> */}
          <Heading
            content="If you are struggling, this is for you"
            {...sectionTitle}
          />
        </Box>
        <Box className="figure" {...imageWrapper}>
          <Image
            fluid={
              (Data.featureImage !== null) | undefined
                ? Data.featureImage.childImageSharp.fluid
                : {}
            }
            alt="Video Preview Image"
          />
          <Box className="fig__caption">
            <Button
              {...buttonStyle}
              icon={<i className="flaticon-youtube" />}
              onClick={handleVideoModal}
              aria-label="play"
            />
          </Box>
        </Box>
        <Box {...btnStyleWrapper}>
          <ButtonGroup />
        </Box>
      </Container>
    </VideoSectionWrapper>
  );
};

// VideoSection style props
VideoSection.propTypes = {
  btnStyle: PropTypes.object,
  btnStyleWrapper: PropTypes.object,
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  memberName: PropTypes.object,
  designation: PropTypes.object,
  imageWrapper: PropTypes.object,
};

// VideoSection default style
VideoSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ["40px", "56px"],
  },
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#10ac84",
    mb: "10px",
  },
  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["26px", "26px", "32px", "40px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
  },
  // button default design
  buttonStyle: {
    variant: "textButton",
    p: 0,
    color: "#ec4444",
    fontSize: "71px",
  },
  btnStyleWrapper: {
    textAlign: "center",
  },
  btnStyle: {
    minWidth: ["120px", "156px"],
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "16px",
  },
  imageWrapper: {
    display: " block",
  },
};

export default VideoSection;
