import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import Text from "common/components/Text";
import Image from "common/components/Image";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import BannerWrapper, {
  BannerContent,
  BannerImage,
} from "./bannerSection.style";

import Atf from "common/assets/image/ATF.jpg";

const BannerSection = ({ btnStyle, title }) => {
  const ButtonGroup = () => (
    <Fragment>
      <Button
        title="Save my spot"
        {...btnStyle}
        onClick={() =>
          (window.location.href =
            "https://www.eventbrite.com/e/the-journey-to-empowerment-tickets-159085580253")
        }
      />
    </Fragment>
  );
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading content="Get empowered, everyday." {...title} />
          </Fade>
          <Fade up delay={200}>
            <Text content="Live virtual event designed to help you master your emotions, reclaim your inner strength, and start living life without fear." />
          </Fade>
          <Fade up delay={300}>
            <ButtonGroup />
          </Fade>
        </BannerContent>
        <BannerImage>
          <Fade up delay={100}>
            <Image src={Atf} alt="Banner" />
          </Fade>
        </BannerImage>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  btnStyle: PropTypes.object,
  title: PropTypes.object,
};

BannerSection.defaultProps = {
  btnStyle: {
    minWidth: ["120px", "156px"],
    fontSize: "14px",
    fontWeight: "300",
  },
  title: {
    fontSize: ["26px", "34px", "42px", "55px"],
    fontWeight: "300",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px"],
    lineHeight: "1.31",
  },
};

export default BannerSection;
