import styled from "styled-components";
import BgImage from "common/assets/image/agency/map.png";

const PartnerSectionWrapper = styled.section`
  padding: 120px 0;
  background-color: #f6fbf9;
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: 25% center;

  @media (max-width: 990px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 80px 0;
  }
  @media (max-width: 575px) {
    padding: 60px 0;
  }
`;

export const ListGroup = styled.div`
  column-count: 1;
  margin-top: 30px;
  color: #343d48cc;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 25px;
  }
  @media only screen and (max-width: 768px) {
    margin: 0;
    max-width: 100%;
    margin-top: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media only screen and (max-width: 411px) {
    column-count: 1;
  }
  .list-item {
    display: flex;
    align-items: center;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 12px;
    }
    img {
      margin-right: 10px;
    }
    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }
`;

export default PartnerSectionWrapper;
